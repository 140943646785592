import logo from './logo.gif';
import insta from './logoInstagram.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Welcome to Keisha Fashion!</h1>
        <h3>
          We sincerely appreciate your interest in our store.
        </h3>
        <h4>Please note that our website is currently under development.</h4>
        <p>For updates, kindly follow us on Instagram.</p>
        <a className='link' href="https://www.instagram.com/">
          <div className='cont-inst'>
            <img className='img-inst' src={insta} alt="" />
            <h6>Follow on Instagram</h6>
          </div>

        </a>

      </header>
    </div>
  );
}

export default App;
